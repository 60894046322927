<template>
  <span>
    <TimerIcon class="mr-1" />
    <span v-if="(hour !== INVALID_TIME)">{{ hour }}:</span>
    <span>{{ base60ZeroPad(minute) }}:</span>
    <span>{{ base60ZeroPad(second) }}</span>
  </span>
</template>

<script>
import TimerIcon from 'mdi-vue/TimerOutline';

const INVALID_TIME = -1;

export default {
  name: 'CountDownTimer',
  components: { TimerIcon },

  props: {
    value: { type: Number, default: 0 }, // must be in seconds
  },
  data() {
    return {
      hour: INVALID_TIME,
      minute: 0,
      second: 0,
      duration: this.value,
      timerObj: null,
    };
  },

  created() {
    this.INVALID_TIME = INVALID_TIME;
    this.startTime = INVALID_TIME;
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },

  methods: {
    startTimer() {
      this.stopTimer(); // stop first any running timer
      this.startTime = Date.now();
      if (this.duration > 0) this.timerObj = setInterval(this.countdown, 500); // 0.5s interval for better precision
    },
    stopTimer() {
      if (this.timerObj) clearInterval(this.timerObj);
      this.timerObj = null;
    },
    countdown() {
      const millis = Date.now() - this.startTime; // unit is milliseconds
      const elapsedSeconds = Math.floor(millis / 1000); // divide milliseconds by 1000 to get seconds
      const remainingSeconds = this.duration - elapsedSeconds;

      if (remainingSeconds < 0) {
        // timeout!
        this.informTimeout();
        this.stopTimer();
      } else {
        // update countdown time
        this.$emit('input', remainingSeconds);
        this.second = (remainingSeconds % 60); // evaluate base 60 modulus to get second hand
        this.minute = Math.floor((remainingSeconds / 60) % 60); // divide by 60 to get minutes and evaluate base 60 modulus to get minute hand
        const hour = Math.floor(remainingSeconds / 3600); // divide by 3600 to get hours
        if (hour > 0 || this.hour !== INVALID_TIME) this.hour = hour;
      }
    },
    informTimeout() {
      this.$emit('timeout');
    },
    base60ZeroPad(num) {
      let s = '00';
      if (num > 0 && num < 60) s = `${(num < 10) ? '0' : ''}${num}`;
      return s;
    },
  },
};
</script>
